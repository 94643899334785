import { ErrorBase } from '@core/models/error.model';

export enum ChangePasswordErrorCode {
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  OLD_PASSWORD_NOT_MATCH = 'OLD_PASSWORD_NOT_MATCH',
}
export class ChangePasswordError {
  public static handle(error: ErrorBase) {
    if (error.code == -7) {
      throw new Error(ChangePasswordErrorCode.OLD_PASSWORD_NOT_MATCH);
    }
    throw new Error(ChangePasswordErrorCode.INTERNAL_SERVER_ERROR);
  }
}
