import { IAccountSetting } from '@features/auth/core/domain/account/account-setting.interface';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { URI_APIs } from '@features/auth/const';
import { HttpClient } from '@angular/common/http';
import { CoreAuthConfigService } from '@features/auth/core-auth-config.service';
import { ChangePasswordError } from '@features/auth/core/domain/account/change-password-error.model';
import { StorageHelper } from '@features/auth/shared/helpers/storage.helper';

@Injectable()
export class AccountSettingService implements IAccountSetting {
  private readonly httpClient = inject(HttpClient);
  private readonly conf = inject(CoreAuthConfigService);

  changeEmailAddress(emailAddress: string): Observable<any> {
    const requestBody = { email: emailAddress };
    return this.httpClient.post(
      this.conf.buildURL(URI_APIs.PROFILE_UPDATE_EMAIL),
      requestBody
    );
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const url = this.conf.buildURL(URI_APIs.PROFILE_CHANGE_PASSWORD);
    const requestBody = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    return this.httpClient
      .post(url, requestBody)
      .pipe(
        catchError((err) => throwError(() => ChangePasswordError.handle(err)))
      );
  }

  // TODO:
  uploadAvatar(avatar: File | null): Observable<any> {
    if (!avatar) {
      throw new Error(`Avatar should not be empty`)
    }
    const url = this.conf.buildURL(URI_APIs.PROFILE_UPLOAD_AVATAR);
    const formData: FormData = new FormData();
    formData.append('avatar', avatar, avatar?.name);
    return this.httpClient.post<any>(url, formData);
  }

  getAccountInfo(): Observable<any> {
    return of(StorageHelper.getUserInfo());
  }
}
