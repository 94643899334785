import {isDevMode} from "@angular/core";
import {ErrorBase} from "@core/models/error.model";
import {AuthInternalServerError} from "@features/auth/core/domain/login/models/version-2/auth-error-version2.model";

export class VerifyOtpCodeExpiredError extends Error {
  public static _code = 457;
  constructor() {
    super(VerifyOtpCodeExpiredError._code.toString());
    isDevMode() && console.error(`Code xác minh đã hết hạn`)
  }
}


export class VerifyOtpCodeNotMatchError extends Error {
  public static _code = 458;
  constructor() {
    super(VerifyOtpCodeExpiredError._code.toString());
    isDevMode() && console.error(`Code xac minh khong khop`)
  }
}

export class SpamResendCodeError extends Error {
  public static _code = 459;
  constructor() {
    super(VerifyOtpCodeExpiredError._code.toString());
    isDevMode() && console.error(`Loi gui yeu cau xac minh nhieu lan`)
  }
}


export class VerifyOTPErrorModel {
  handle(response: ErrorBase) {
    switch (response.code) {
      case -1:
        throw new VerifyOtpCodeExpiredError();
      default:
        throw new AuthInternalServerError();
    }
  }
}
